import styles from "./Home.module.css";
import Image from "next/image";
import pin from "../../assets/images/header/pin.png";
import googleplay from "../../assets/images/home/googleplay.png";
import apple from "../../assets/images/home/apple.png";
import mobileimg from "../../assets/images/home/mobileimg.webp";
import icon1 from "../../assets/images/home/toppers.webp";
import icon2 from "../../assets/images/home/faculty.webp";
import icon3 from "../../assets/images/home/guidance.webp";
import icon4 from "../../assets/images/home/mentoring.webp";
import icon5 from "../../assets/images/home/test series.webp";
import icon6 from "../../assets/images/home/program.webp";
import preferredcivil from "../../assets/images/home/preferredcivil.webp";
import medal from "../../assets/images/home/medal.webp";
import youtube_thumnail_1 from "../../assets/images/home/youtube/youtube_thumnail_33.webp";
import youtube_thumnail_2 from "../../assets/images/home/youtube/youtube_thumnail_22.webp";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Close from "../../assets/images/home/Icon ionic-ios-close-circle.png";
// import Sadhana from "../../assets/images/home/Pre-book-2024_web-popup.webp";
// import Upsc_popup from "../../assets/images/home/UPSC Delhi.webp";
import Link from "next/link";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import dynamic from "next/dynamic";
import { getCookie } from 'cookies-next';
const Acheiver = dynamic(() => import("../../components/our-acheiver/achievers"));
// import carouselBGimg1 from "../../assets/images/home/Banner/Interview-fb-web-banner.webp";
// import carouselBGimg2 from "../../assets/images/home/Banner/Pre-book-2024_Webbanner.webp";
// import carouselBGimg3 from "../../assets/images/home/Banner/CSAT-PLUS_Web_Banner_1903x438_08_01_2024 (1).webp";
// import carouselBGimg4 from "../../assets/images/home/Banner/TNPSC--GR-I-&-II-Prelims-webbanner-(Clickhere).jpg";
// import carouselBGimg5 from "../../assets/images/home/Banner/Prefit-Web-Banner-batch3.jpg";
// import carouselBGimg6 from "../../assets/images/home/Banner/prestorming-web-banner-batch-VI-with-button.jpg";
// import carouselBGimg7 from "../../assets/images/home/Banner/Result-2022-Web-Banner-2.webp";
// import carouselBGimg8 from "../../assets/images/home/Banner/TNPSC  GR - IV Webbanner_2 copy.webp";
// import carouselBGimg9 from "../../assets/images/home/Banner/TNPSC-location-Banner---Web-banner-(Click-here-).jpg";
// import carouselBGimg10 from "../../assets/images/home/Banner/Sadhana---Batch-III-_Web-Banner.webp";
// import carouselBGimg11 from "../../assets/images/home/Banner/TNPSC_GMAT_Webbanner_23_1_2024 (1).jpg";
// import carouselBGimg12 from "../../assets/images/home/Banner/Perlims mock test-web banner-button.jpg";
// import carouselBGimg13 from "../../assets/images/home/Banner/TNPSC-Group-I-Prelims-Test-Series---Web-Banner.jpg";
// import carouselMBimg1 from "../../assets/images/home/Mob_Banner/Interview-fb-Mob-banner-(Click-Here).webp";
// import carouselMBimg2 from "../../assets/images/home/Mob_Banner/Pre-book-2024_Mobile-Banner.webp";
// import carouselMBimg3 from "../../assets/images/home/Mob_Banner/CSAT-PLUS_Mobile_Banner_905x1280_08_01_2024.webp";
// import carouselMBimg4 from "../../assets/images/home/Mob_Banner/TNPSC--GR-I-&-II-Prelims-Mobile-banner-(Click-here).jpg";
// import carouselMBimg5 from "../../assets/images/home/Mob_Banner/Prefit-Mobile-Banner-button.jpg";
// import carouselMBimg5 from "../../assets/images/home/Mob_Banner/Prefit-Mobile-Banner.webp";
// import carouselMBimg6 from "../../assets/images/home/Mob_Banner/prestorming-Mob-banner-batch-VI-without-button.jpg";
// import carouselMBimg7 from "../../assets/images/home/Mob_Banner/Result-2022-Mobile-Banner.webp";
// import carouselMBimg8 from "../../assets/images/home/Mob_Banner/TNPSC  GR - IV Mobile banner-01.webp";
// import carouselMBimg9 from "../../assets/images/home/Mob_Banner/TNPSC--location-Banner---Mobile-banner-(Click-Here).jpg";
// import carouselMBimg10 from "../../assets/images/home/Mob_Banner/Sadhana---Batch-III-_Mobile-Banner.webp";
// import carouselMBimg11 from "../../assets/images/home/Mob_Banner/TNPSC_GMAT_Mobile-banner_23_1_2024 (1).jpg";
// import carouselMBimg12 from "../../assets/images/home/Mob_Banner/Prelims-Web-mobile---button.jpg";
// import carouselMBimg13 from "../../assets/images/home/Mob_Banner/TNPSC-Group-I-Prelims-Test-Series---Mobile-Banner.jpg";
import Newicon from "../../assets/images/header/new.svg";
import Head from "next/head";

const Homepage = () => {

  const [modalIsOpen, setIsOpen] = useState(true);
  const closeModal = () => setIsOpen(false);
  // const openModal = () => setIsOpen(true);

  const [play, setPlay] = useState(false);
  const [play1, setPlay1] = useState(false);

  // const BGimg = [
  //   { quickLoading: false, img: carouselBGimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
  //   { quickLoading: false, img: carouselBGimg12, alt: "carousel", link: "/sia-all-india-prelims-mock-test" },
  //   { quickLoading: false, img: carouselBGimg13, alt: "carousel", link: "/tnpsc-group-i-prelims-test-series" },
  //   { quickLoading: true, img: carouselBGimg10, alt: "carousel", link: "/two-year-upsc-program" },
  //   { quickLoading: false, img: carouselBGimg1, alt: "carousel", link: "/upsc-interview" },
  //   { quickLoading: false, img: carouselBGimg6, alt: "carousel", link: "/upsc-prestorming" },
  //   { quickLoading: false, img: carouselBGimg5, alt: "carousel", link: "/upsc-prefit-test" },
  //   { quickLoading: false, img: carouselBGimg8, alt: "carousel", link: "/tnpsc-group-iv-test-batch" },
  //   { quickLoading: false, img: carouselBGimg3, alt: "carousel", link: "/csat-plus" },
  //   { quickLoading: false, img: carouselBGimg7, alt: "carousel", link: "/our-achievers" },
  //   { quickLoading: false, img: carouselBGimg4, alt: "carousel", link: "/tnpsc-admissions" },
  //   { quickLoading: false, img: carouselBGimg9, alt: "carousel", link: "/tnpsc-admissions" },
  //   { quickLoading: false, img: carouselBGimg11, alt: "carousel", link: "/tnpsc-general-enrichment-programme" }
  // ]

  // const MBimg = [
  //   { quickLoading: false, img: carouselMBimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
  //   { quickLoading: false, img: carouselMBimg12, alt: "carousel", link: "/sia-all-india-prelims-mock-test" },
  //   { quickLoading: false, img: carouselMBimg13, alt: "carousel", link: "/tnpsc-group-i-prelims-test-series" },
  //   { quickLoading: true, img: carouselMBimg10, alt: "carousel", link: "/two-year-upsc-program" },
  //   { quickLoading: false, img: carouselMBimg1, alt: "carousel", link: "/upsc-interview" },
  //   { quickLoading: false, img: carouselMBimg6, alt: "carousel", link: "/upsc-prestorming" },
  //   { quickLoading: false, img: carouselMBimg5, alt: "carousel", link: "/upsc-prefit-test" },
  //   { quickLoading: false, img: carouselMBimg8, alt: "carousel", link: "/tnpsc-group-iv-test-batch" },
  //   { quickLoading: false, img: carouselMBimg3, alt: "carousel", link: "/csat-plus" },
  //   { quickLoading: false, img: carouselMBimg7, alt: "carousel", link: "/our-achievers" },
  //   { quickLoading: false, img: carouselMBimg4, alt: "carousel", link: "/tnpsc-admissions" },
  //   { quickLoading: false, img: carouselMBimg9, alt: "carousel", link: "/tnpsc-admissions" },
  //   { quickLoading: false, img: carouselMBimg11, alt: "carousel", link: "/tnpsc-general-enrichment-programme" },
  // ]

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Best IAS Academy in Chennai | Shankar IAS Academy",
    "url": "https://www.shankariasacademy.com/",
    "logo":
      "https://www.shankariasacademy.com/_next/static/media/SIA%20Logo.f7bc4ef0.png",
    "description": "Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results.",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+91-7667766266",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-66024500",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-43533445",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-45543082",
        "contactType": "UPSC, TNPSC Admissions"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/shankariasacademy",
      "https://twitter.com/shankarias",
      "https://www.instagram.com/shankariasacademy/",
      "https://www.youtube.com/c/ShankarIASAcademyVideos"
    ]
  }

  const [notificationsection, setnotificationsection] = useState(false);
  const [notificationcookie, setnotificationcookie] = useState(true);

  const dummyapis=[{
    active: true,
    closedOn: null,
    createOn: "",
    hyperLink: null,
    id:"",
    title: "",
    description: "",
    type: "",
    updatedOn: null
  }]

  const dummyhyperlinks=[{
    hyperlink:"",
    websiteNotificationPopUp:"",
    title: ""
  }]

  const [notification, setNotification] = useState(dummyapis);
  const [hyperlink, setHyperlink] = useState(dummyhyperlinks);

  const callAPI = async () => {
		try {
			const res = await fetch(
				`https://admin.shankariasacademy.com/siaadminpanel/website/web/Notification/get/active`
			);
			const data = await res.json();
      // console.log(data);

      if(data.status==200){
        setnotificationsection(true);
        setNotification(data.data[0]);
        setHyperlink(data.data);
      }
       else{
        setnotificationsection(false);
      }
       setnotificationcookie(getCookie('Notification'));
		} catch (err) {
      setnotificationsection(false);
		}
	};

  useEffect(() => {
    let session = sessionStorage.getItem('notify')
    if (session == null) {
      callAPI()
      // openModal()
      sessionStorage.setItem('notify', true);
    }
  }, [notificationcookie]);

  const carouselget = [
    {
    id:"",   
    websiteImageUrl:"",   
    mobileImageUrl:"",   
    description:"",   
    hyperLink:"",    
    priority:"",    
    deleted:"",    
    department:"",    
    createdOn:"",   
    deviceType:""    
    }
  ]

  const [carouselcontent, setcarouselcontent] = useState(carouselget);   
  const [carouselsection, setcarouselsection] = useState(false);

  const carouselapi = async () => {
    try {      
    const res = await fetch(    
    'https://admin.shankariasacademy.com/siaadminpanel/website/banner/HOME/get?id=all'   
    );   
    const content1 = await res.json();   
    // console.log(content1.data);   
    if(content1.status==200 || content1.status==201){   
    setcarouselsection(true);    
    setcarouselcontent(content1.data);   
    }   
    else{   
    setcarouselsection(false);   
    }   
    } catch (err) {   
    // console.log(err);
    setcarouselsection(false);   
    }    
  };

  useEffect(() => {
    carouselapi() 
  },[])

  return (
    <>
      <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify( jsonLd,null, "\t") .replaceAll("],\n\t\"", "],\n\n\t\"") }} />
        <meta charset="UTF-8" />
        <link rel="icon" href="./logo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noodp,noydir" />
        <meta name="robots" content="index,follow" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta property="article:publisher" content="https://www.facebook.com/shankariasacademy" />
        <meta property="article:modified_time" content="2024-01-25T05:43:44+00:00" />
        <title>Best IAS Academy in Chennai | Shankar IAS Academy</title>
        <meta name="title" content="Best IAS Academy in Chennai | Shankar IAS Academy" />
        <meta name="description" content="Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results." />
        <link rel="canonical" href="https://www.shankariasacademy.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best IAS Academy in Chennai | Shankar IAS Academy" />
        <meta property="og:description" content="Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results." />
        <meta property="og:url" content="https://www.shankariasacademy.com/" />
        <meta property="og:site_name" content="Shankar IAS Academy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@shankarias" />
      </Head>
    
    {/* Dynamic Modal */}
    {notificationsection && 
      <div>
        <Modal
          size="md"
          centered
          show={modalIsOpen}
          onHide={closeModal}
          aria-label="UPSC Modal"
          className="blur"
        >
          <Modal.Body className="p-0">
            <div className="position-absolute p-0 p-md-1 closebtn">
              <Image loading="eager" placeholder="blur" onClick={closeModal} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer", width: "20px" }} />
            </div>
            {hyperlink?.map((item, index) => (
              <Link href={`${item?.hyperlink}`} rel="noreferrer" aria-label="Modal Link" key={index}>
                <Image src={item?.websiteNotificationPopUp} width={715} height={600} alt={item.title} title={item.title} className="img-fluid w-100" />
              </Link>
            ))}
          </Modal.Body>
        </Modal>
      </div>
    }  

      <Navbar title="home" />

  {/* Dynamic Carousel */}
    {carouselsection && 
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0"> 
            <Carousel> 
              {carouselcontent?.map((item,index)=>(
              (item.mobileImageUrl && item.websiteImageUrl) ?
                <Carousel.Item key={index}>
                  <Link href={item?.hyperLink ? item.hyperLink : ""} onClick={(e)=>item.hyperLink ? "" : e.preventDefault()}>
                    <div>
                      <Image
                        key={index}
                        className="d-none d-md-block img-fluid w-100"
                        width={1536}
                        height={354}
                        src={item.websiteImageUrl}
                        alt={item.description}
                        title={item.description}
                      />
                      <Image
                        className="d-block d-md-none img-fluid w-100"
                        width={905}
                        height={1280}
                        src={item.mobileImageUrl} 
                        alt={item.description} 
                        title={item.description} 
                      />
                    </div>
                  </Link>
                </Carousel.Item>
              :""
              ))} 
            </Carousel>
          </div>
        </div>
      </div>
    }

      {/* Carousel large devices */}
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-md-block">
            <Carousel>
              {BGimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid w-100 h-100"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div> */}

      {/* Carousel Mobile devices */}
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-block d-md-none">
            <Carousel>
              {MBimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div> */}

      {/* For Large Device */}
      <div className="container pt-5 d-none d-md-block">
        <div className="row mx-auto">
          <div className={`${styles.coursesimg} col-md-6`}>
            <div className="row mx-2">
              <div className="col-md-8">
                <h2
                  className={`${styles.sectiontitle} ${styles.fonthover} pt-5`}
                >
                  UPSC Courses
                </h2>
              </div>
              <div className="col-md-4 pt-5 mt-3 pe-0 text-end">
                <Link
                  href="/about-upsc"
                  className={`${styles.acheiverrank} ${styles.fonthover, styles.courseknowmore}`}
                  id="upsccourseknowmore"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Know More
                </Link>
              </div>
              <ul className="list-unstyled">
                <li className="pb-3">
                  <Link
                    href="/upsc-admission-anna-nagar"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="upsc-ias-ips-admissioncourseshover1"
                  >
                    UPSC - Classroom & Online
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/upsc-interview"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="upsc-interviewcourseshover1"
                  >
                    UPSC CSE 2023 FREE Interview Coaching
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/upsc-prelims-test-series"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="prefit-2024"
                  >
                    UPSC Prelims Test Series 2024
                  </Link>
                </li>
                {/* <li className="pb-3">
                  <Link
                    href="/upsc-mains-test-series-2023"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="upsc-mains-test-series-2023courseshover1"
                  >
                    Mains 2023 Test Series
                  </Link>
                </li> */}
                <li className="pb-3">
                  <Link
                    href="/ifs-interview"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="forestrycourseshover1"
                  >
                    UPSC IFS 2023 FREE Interview Coaching
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/upsc-environment-book"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="upsc-environmentbookhover1"
                  >
                    UPSC Environment Book Buy Now
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/sia-all-india-prelims-mock-test"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="allindiaprelimshover1"
                  >
                    All India Prelims Mock Test 2024
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="row"
              style={{ border: "1px solid #D9DFE6", borderLeft: "none" }}
            >
              <div className="col-md-8">
                <h2 className={`${styles.sectiontitle} ps-2 pt-5`}>
                  TNPSC Courses
                </h2>
              </div>
              <div className="col-md-4 pt-5 mt-3 text-end">
                <Link
                  href="/tnpsc-admissions"
                  className={`${styles.acheiverrank} ${styles.fonthover, styles.courseknowmore}`}
                  id="courseshover"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Know More
                </Link>
              </div>
              <ul className="list-unstyled px-4 pb-3">
              <li className="pb-3">
                  <Link
                    href="/tnpsc-group-i-prelims-test-series"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-group-i-prelims-test-series"
                  >
                    TNPSC Group I & II Prelims Test Series 2024<span><Image src={Newicon} alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/tnpsc-admissions"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-admissionscourseshover1"
                  >
                    Gr I, II & IIA Prelims Coaching
                  </Link>
                </li>
                {/* <li className="pb-3">
                  <Link
                    href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&ifkv=ASKXGp1YLKv7QkngPqJtkL_P79ajBkZdNb8EwaW0mOhjWzEruq_teiol2FUg7p9ydl78aZ6xee2byQ&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1728887634%3A1704693503982050&theme=glif"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-admissionscourseshover1"
                  >
                    TNPSC Group II (OT) Free Mock Interview 2024
                  </Link>
                </li> */}
                <li className="pb-3">
                  <Link
                    href="/tnpsc-general-enrichment-programme"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-mains-paper-1courseshover2"
                  >
                    TNPSC-GMAT Enrichment Programme Group II & IV 2024
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/tnpsc-group-iv-test-batch"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-mains-paper-1courseshover2"
                  >
                    TNPSC Group IV Test Series 2024
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/tnpsc-group-iv-workshop"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-group-iv-workshop"
                  >
                    TNPSC Group IV Class-cum-Workshop 2023
                  </Link>
                </li>
              </ul>
              <hr />
              <div className="row">
                <div className="col">
                  <h2 className={`${styles.sectiontitle} ps-2`}>
                    Banking Courses
                  </h2>
                  <ul className="list-unstyled ps-2">
                    <li className="pb-3">
                      <a href="https://www.shankarbankingacademy.com/" target="_blank" rel="noreferrer"
                        className={`${styles.acheiverrank, styles.courseshover}`}
                        id="bankingcourseshover1"
                      >
                        Banking/SSC
                      </a>
                    </li>
                    <li className="pb-3">
                      <Link
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdBcEeSW1BApjoMctUBWl3zqId4upMyJIZcmj66ayyoP0_m6w/viewform?usp=sf_link"
                        className={`${styles.acheiverrank, styles.courseshover2}`}
                        id="IBPS-PO-mock-interview-courseshover2"
                        target="_blank" rel="noreferrer"
                      >
                        IBPS SO Mock interview 2024
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Mobile Device */}
      <div className="container pt-4 d-block d-md-none">
        <div className="row mx-auto" style={{ border: "1px solid #D9DFE6" }}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <h2 className={`${styles.sectiontitle} pt-3`}>
                      UPSC Courses
                    </h2>
                  </div>
                  <div className="col-4 pt-4">
                    <Link
                      href="/about-upsc"
                      className={`${styles.acheiverrank, styles.courseknowmore}`}
                      id="upsccourseknowmoreformobile"
                      style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                    >
                      Know More
                    </Link>
                  </div>
                </div>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link
                      href="/upsc-admission-anna-nagar"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="upsc-ias-ips-admissioncourseshover2"
                    >
                      UPSC - Classroom & Online
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/upsc-interview"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="upsc-interviewcourseshover2"
                    >
                      UPSC CSE 2023 FREE Interview Coaching
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/upsc-prelims-test-series"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="prefit-2024"
                    >
                      UPSC Prelims Test Series 2024
                    </Link>
                  </li>
                  {/* <li className="mb-2">
                    <Link
                      href="/upsc-mains-test-series-2023"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="upsc-mains-test-series-2023courseshover2"
                    >
                      Mains 2023 Test Series
                    </Link>
                  </li> */}
                  <li className="mb-2">
                    <Link
                      href="/ifs-interview"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="forestrycourseshover2"
                    >
                      UPSC IFS 2023 FREE Interview Coaching
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/upsc-environment-book"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="upsc-environmentbookhover1"
                    >
                      UPSC Environment Book Buy Now
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/sia-all-india-prelims-mock-test"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1"
                    >
                      All India Prelims Mock Test 2024
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <h2 className={`${styles.sectiontitle} pt-3`}>
                      TNPSC Courses
                    </h2>
                  </div>
                  <div className="col-4 pt-4">
                    <Link
                      href="/tnpsc-admissions"
                      className={`${styles.acheiverrank, styles.courseknowmore}`}
                      id="tnpsccourseknowmore"
                      style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                    >
                      Know More
                    </Link>
                  </div>
                </div>

                <ul className="list-unstyled">
                <li className="mb-2">
                  <Link
                    href="/tnpsc-group-i-prelims-test-series"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-group-i-prelims-test-series"
                  >
                    TNPSC Group I & II Prelims Test Series 2024<span><Image src={Newicon} alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>
                  <li className="mb-2">
                    <Link
                      href="/tnpsc-admissions"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-admissionscourseshover1"
                    >
                      Gr I, II & IIA Prelims Coaching
                    </Link>
                  </li>
                  {/* <li className="mb-2">
                    <Link
                      href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&ifkv=ASKXGp1YLKv7QkngPqJtkL_P79ajBkZdNb8EwaW0mOhjWzEruq_teiol2FUg7p9ydl78aZ6xee2byQ&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1728887634%3A1704693503982050&theme=glif"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-admissionscourseshover1"
                    >
                      TNPSC Group II (OT) Free Mock Interview 2024
                    </Link>
                  </li> */}
                  <li className="mb-2">
                    <Link
                      href="/tnpsc-general-enrichment-programme"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-mains-paper-1courseshover2"
                    >
                      TNPSC-GMAT Enrichment Programme Group II & IV 2024
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-iv-test-batch"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-mains-paper-1courseshover2"
                    >
                      TNPSC Group IV Test Series 2024
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-iv-workshop"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-group-iv-workshop"
                    >
                      TNPSC Group IV Class-cum-Workshop 2023
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <hr />
          </div>
          <div className="col">
            <h2 className={`${styles.sectiontitle}`}>Banking Courses</h2>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.shankarbankingacademy.com/" target="_blank" rel="noreferrer"
                  className={`${styles.acheiverrank, styles.courseshover} pt-3`}
                  id="bankingcourseshover2"
                >
                  Banking/SSC
                </a>
              </li>
              <li>
                <Link
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdBcEeSW1BApjoMctUBWl3zqId4upMyJIZcmj66ayyoP0_m6w/viewform?usp=sf_link"
                  className={`${styles.acheiverrank, styles.courseshover2}`}
                  id="IBPS-PO-mock-interview-courseshover2"
                  target="_blank" rel="noreferrer"
                >
                  IBPS SO Mock interview 2024
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Location From Medium Device */}
      <div className="container-fluid" style={{ backgroundColor: "#FFF2F2" }}>
        <div className="container d-none d-md-block pt-5">
          <div className="row mx-auto" style={{ backgroundColor: "#FFF2F2" }}>
            <div className={`${styles.couresesimg1} col-lg-6 col-12 p-5`}>
              <h2 className={`${styles.sectiontitle} mb-0`}>
                Mentoring thousands of
              </h2>
              <h2 className={`${styles.sectiontitle}`}>aspirants across India</h2>
              <Link href="/contact-us">
                <button className={`${styles.normalbutton}`} type="submit">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className="col-lg-6 col-12 pt-5 p-3">
              <div className="row mx-auto">
                <div className="col-md-5 p-3">
                  <Link href="/upsc-admission-anna-nagar" className={`${styles.locationid, styles.courseshover}`} id="upsc-ias-ips-admissioncourseshover3">
                    <Image src={pin} alt="Shankar IAS Academy Anna nagar" title="Anna nagar branch" />
                    &nbsp; &nbsp; Anna Nagar
                  </Link>
                  <hr />
                  <Link href="/adyar" className={`${styles.locationid, styles.courseshover}`} id="adyarcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Adyar" title="Adyar branch" />
                    &nbsp; &nbsp; Adyar
                  </Link>
                  <hr />
                  <Link href="/bengaluru" className={`${styles.locationid, styles.courseshover}`} id="bengalurucourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Bengaluru" title="Bengaluru branch" />
                    &nbsp; &nbsp; Bengaluru
                  </Link>
                  <hr />
                  <Link href="/delhi" className={`${styles.locationid, styles.courseshover}`} id="delhicourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Delhi" title="Delhi branch" />
                    &nbsp; &nbsp; Delhi
                  </Link>
                  <hr />
                  <Link href="/thiruvananthapuram" className={`${styles.locationid, styles.courseshover}`} id="thiruvananthapuramcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Thiruvananthapuram" title="Thiruvananthapuram branch" />
                    &nbsp; &nbsp; Thiruvananthapuram
                  </Link>
                  <hr />
                </div>
                <div className="col-md-5 p-3">
                  <Link href="/trichy" className={`${styles.locationid, styles.courseshover}`} id="trichycourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Trichy" title="Trichy branch" />
                    &nbsp; &nbsp; Trichy
                  </Link>
                  <hr />
                  <Link href="/salem" className={`${styles.locationid, styles.courseshover}`} id="salemcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Salem" title="Salem branch" />
                    &nbsp; &nbsp; Salem
                  </Link>
                  <hr />
                  <Link href="/madurai" className={`${styles.locationid, styles.courseshover}`} id="maduraicourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Madurai" title="Madurai branch" />
                    &nbsp; &nbsp; Madurai
                  </Link>
                  <hr />
                  <Link href="/coimbatore" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Coimbatore" title="Coimbatore branch" />
                    &nbsp; &nbsp; Coimbatore
                  </Link>
                  <hr />
                  <Link href="/hyderabad" className={`${styles.locationid, styles.courseshover}`} id="hyderabadcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Hyderabad" title="Hyderabad branch" />
                    &nbsp; &nbsp; Hyderabad
                  </Link>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Location For Small Device */}
      <div className="container-fluid" style={{ backgroundColor: "#FFF2F2" }}>
        <div className="container d-block d-md-none pt-4">
          <div className="row mx-auto" style={{ backgroundColor: "#FFF2F2" }}>
            <div className={`${styles.couresesimg1} col-md-5 pt-5`}>
              <h2 className={`${styles.sectiontitle} mb-0`}>
                Mentoring thousands of
              </h2>
              <h2 className={`${styles.sectiontitle}`}>aspirants across India</h2>
              <Link href="/contact-us">
                <button className={`${styles.normalbutton}`} type="submit">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className="row pb-4">
              <div className="col-md-5 p-3">
                <Link href="/upsc-admission-anna-nagar" className={`${styles.locationid, styles.courseshover}`} id="upsc-ias-ips-admissioncourseshover4">
                  <Image src={pin} alt="Shankar IAS Academy Anna nagar" title="Anna nagar branch" />
                  &nbsp; &nbsp; Anna Nagar
                </Link>
                <hr />
                <Link href="/adyar" className={`${styles.locationid, styles.courseshover}`} id="adyarcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Adyar" title="Adyar branch" />
                  &nbsp; &nbsp; Adyar
                </Link>
                <hr />
                <Link href="/bengaluru" className={`${styles.locationid, styles.courseshover}`} id="bengalurucourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Bengaluru" title="Bengaluru branch" />
                  &nbsp; &nbsp; Bengaluru
                </Link>
                <hr />
                <Link href="/delhi" className={`${styles.locationid, styles.courseshover}`} id="delhicourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Delhi" title="Delhi branch" />
                  &nbsp; &nbsp; Delhi
                </Link>
                <hr />
                <Link href="/thiruvananthapuram" className={`${styles.locationid, styles.courseshover}`} id="thiruvananthapuramcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Thiruvananthapuram" title="Thiruvananthapuram branch" />
                  &nbsp; &nbsp; Thiruvananthapuram
                </Link>
                <hr />
                <Link href="/trichy" className={`${styles.locationid, styles.courseshover}`} id="trichycourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Trichy" title="Trichy branch" />
                  &nbsp; &nbsp; Trichy
                </Link>
                <hr />
                <Link href="/salem" className={`${styles.locationid, styles.courseshover}`} id="salemcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Salem" title="Salem branch" />
                  &nbsp; &nbsp; Salem
                </Link>
                <hr />
                <Link href="/madurai" className={`${styles.locationid, styles.courseshover}`} id="maduraicourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Madurai" title="Madurai branch" />
                  &nbsp; &nbsp; Madurai
                </Link>
                <hr />
                <Link href="/coimbatore" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Coimbatore" title="Coimbatore branch" />
                  &nbsp; &nbsp; Coimbatore
                </Link>
                <hr />
                <Link href="/hyderabad" className={`${styles.locationid, styles.courseshover}`} id="hyderabadcourseshover1">
                  <Image src={pin} alt="Shankar IAS Academy Hyderabad" title="Hyderabad branch" />
                  &nbsp; &nbsp; Hyderabad
                </Link>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Online Class For Large Device */}
      <div className="container-fluid" style={{ backgroundColor: "#E2F6FF" }}>
        <div className="container d-none d-md-block mb-0 pt-5">
          <div className="row mx-auto" style={{ backgroundColor: "#E2F6FF" }}>
            <div className="col-xl-5 col-md-12 col-12 p-5">
              <h2 className={`${styles.sectiontitle}`}>
                Looking for online classes?
              </h2>
              <p className={`${styles.acheiverrank} mb-0`}>
                Learn from anywhere, anytime.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                Missed your classes? Worry not.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                We got you covered with our recorded sessions.
              </p>
              <div className="row pt-3">
                <div className="col-xl-5 col-md-4 ps-0">
                  <a href="https://play.google.com/store/apps/details?id=com.vinbytes.shankar_ias" target="_blank" rel="noreferrer">
                    <u className="pe-2">
                      <Image src={googleplay} alt="Google play" title="Google play" />
                    </u>
                  </a>
                </div>
                <div className="col-xl-5 col-md-4 py-2">
                  <a href="https://apps.apple.com/in/app/shankar-ias/id1611747971" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={apple} alt="Apple store" title="Apple store" />
                    </u>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-12">
              <Image
                src={mobileimg}
                alt="Mobile Image banner"
                title="Looking for online classes?"
                className={`image-fluid w-100 h-100 ${styles.lookingForOnlineClass}`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Online Class For Small Device */}
      <div className="container-fluid" style={{ backgroundColor: "#E2F6FF" }}>
        <div className="container d-block d-md-none mb-0 pt-4">
          <div className="row mx-auto" style={{ backgroundColor: "#E2F6FF" }}>
            <div className="col-sm-12 pt-3">
              <h2 className={`${styles.sectiontitle}`}>
                Looking for online classes?
              </h2>
              <p className={`${styles.acheiverrank} mb-0`}>
                Learn from anywhere, anytime.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                Missed your classes? Worry not.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                We got you covered with our recorded sessions.
              </p>
              <div className="row mb-0">
                <div className="col-6">
                  <a href="https://play.google.com/store/apps/details?id=com.vinbytes.shankar_ias" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={googleplay} alt="Google play" title="Google play" />
                    </u>
                  </a>
                </div>
                <div className="col-6 mx-md-2 py-2">
                  <a href="https://apps.apple.com/in/app/shankar-ias/id1611747971" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={apple} alt="Apple store" title="Apple store" />
                    </u>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <Image
                src={mobileimg}
                alt="Mobile Image banner"
                title="Looking for online classes?"
                className="image-fluid w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>


      {/* Why Shankar IAS Academy */}
      <div className="container pt-md-5 pt-4">
        <div className="row">
          <div className="col">
            <h1 className={`${styles.sectiontitle} text-center mb-0`}>
              Why Shankar IAS Academy?
            </h1>
          </div>
        </div>
      </div>

      <div className="container pt-md-5 pt-4">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Link href="/our-achievers"><Image src={icon1} alt="Tnpsc & upsc toppers" title="Shankar IAS Academy Toppers" className="img-fluid" /></Link>              <div className="mx-3 mx-md-4">
                <a href="/our-achievers" className={`${styles.whyshankarcont} text-start mb-0 text-decoration-underline`}>Consistently Producing Toppers Since 2004</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Link href="/our-faculty"><Image src={icon2} alt="Shankar IAS Academy faculty" title="Experienced Faculty Team" className="img-fluid" /></Link>              <div className="mx-3 mx-md-4">
                <a href="/our-faculty" className={`${styles.whyshankarcont} text-start mb-0 text-decoration-underline`}>Experienced Faculty Team (10+ Years)</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Image src={icon3} alt="Tnpsc & upsc achievers" title="Shankar IAS Academy Achievers" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>Regular Guidance From Achievers</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Image src={icon4} alt="Tnpsc & upsc Mentoring" title="Shankar IAS Academy Personalised And Scientific Mentoring" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>Personalised And Scientific Mentoring</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Image src={icon5} alt="Tnpsc & upsc Test Series" title="Shankar IAS Academy Test Series" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>AI Based Test Series (100+ Prelims, 20+ Mains)</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Image src={icon6} alt="Tnpsc & upsc Interview Program" title="Shankar IAS Academy Interview Program With Coaching And Mock Interviews." className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>3 Months Interview Program With Coaching And Mock Interviews.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col py-3 text-center">
              <Link href={"/why-shankar-ias"} rel="preconnect" className={`${styles.viewbuttonalink}`}>
                <button type="button" className={`${styles.viewbutton}`}>
                  Know More
                </button>
              </Link>
            </div>
          </div>

        </div>
      </div>

      <div className="container pt-3">
        <div className="col text-center">
          <p className={`${styles.civilserviveheading}`}>
            PREFERRED CIVIL SERVICES TRAINING PARTNER OF
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col text-center">
            <Image
              src={preferredcivil}
              alt="Shankar IAS Academy Civil Service"
              title="Shankar IAS Academy CIVIL SERVICES TRAINING PARTNER"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* Our Achievments */}
      <div className="container-fluid pt-md-5 pt-4 px-0">
        <div className="row mx-auto">
          <div className={`${styles.bground} mb-5`}>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <Image src={medal} alt="Our Achievements" title="Best IAS Academy in India" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="container pt-4">
              <div className="row">
                <div className="col">
                  <h2 className={`${styles.sectiontitle} text-center mb-0`}>
                    Our Achievements
                  </h2>
                  <h3
                    className={`${styles.sectionsubtitle} text-center`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    The Best IAS Academy in India
                  </h3>
                </div>
              </div>

              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-md-8">
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    All over India, people aspiring to become IAS, IPS and other elite bureaucratic service officers and seeking to fine tune their preparation, flock to Shankar IAS Academy for Civil Service coaching. With branches in
                  </p>
                  <p className={`${styles.acheiverrank} text-center`}>
                    Chennai, Delhi, Bengaluru, Thiruvananthapuram, Trichy, Salem, Madurai and Coimbatore, it is the Academy of choice for Civil Service aspirants.
                  </p>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row pt-md-5">
                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>2200+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected for IAS,
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    IPS, IFS and other Elite
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Services from our institute
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>336</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    out of 933 Selections in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    UPSC CSE 2022 Results
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>20</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Successful Candidates
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    in top 50
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>2000+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected for
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Group I, II and  IV
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    services from our Institute
                  </p>
                </div>
              </div>

              <div className="row pt-md-4 justify-content-center">
                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>54</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Selections out of 181 Vacancies in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    TNPSC Group I Results 2019
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>450+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Selections out of 1338 Vacancies in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    TNPSC Group 2(OT) Results 2019
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>1000+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected across
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Various Banks from our Institute
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Acheivers */}
      <div className="container">
        <div className="row">
          <div className="col pt-4 pt-md-5 text-center">
            <h2 className={`${styles.sectiontitle} mb-0`}>Our Achievers</h2>
            <p className={`${styles.sectionsubtitle}`}>
              Rank Holders from our Academy
            </p>
          </div>
        </div>
      </div>
      <Acheiver />

      <div className={`${styles.bg} container-fluid py-5 mt-4 mt-md-5`}>
        <div className="row reveal">
          <div className="container">

            <div className="row">
              <div className="col text-center">
                <h2 className={`${styles.sectiontitle} mb-0`}>Testimonials</h2>
                <p className={`${styles.sectionsubtitle}`}>
                  Hear it from our Achievers
                </p>
              </div>
            </div>

            <div className="ps-0 pe-0">

              <div className="container mb-2 mb-xl-5">
                <div className="row">
                  <div className="col-12 col-lg-6 my-md-3 px-xl-3">
                    {
                      play ?
                        (<iframe src={"https://www.youtube.com/embed/6Igk3TY0oUo?autoplay=1"} className="w-100 h-100  mb-md-3" id="homeyoutube" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderWidth: '0' }}></iframe>)
                        :
                        (<Image src={youtube_thumnail_1} style={{ cursor: 'pointer' }} onClick={() => setPlay(true)} className="img-fluid h-100 w-100  mb-md-3"
                          id="homeyoutube" alt="youtube thumbnail"></Image>)
                    }
                    <div className="d-none d-md-block"><Link
                      href="https://www.iasparliament.com/UPSC-IAS-daily-current-videos"
                      target="_blank"
                      className={`${styles.acheiverrank, styles.courseknowmore} float-end`}
                    >
                      All Current Affairs
                    </Link></div>
                  </div>
                  <div className="col-12 col-lg-6 pt-3 pt-md-0 my-md-3 px-xl-3">
                    {
                      play1 ?
                        (<iframe src={"https://www.youtube.com/embed/iLafRlN6e5E?autoplay=1"} className="w-100 h-100" id="homeyoutube" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderWidth: '0' }}></iframe>)
                        :
                        (<Image src={youtube_thumnail_2} style={{ cursor: 'pointer' }} onClick={() => setPlay1(true)} className="img-fluid h-100 w-100"
                          id="homeyoutube" alt="youtube thumbnail"></Image>)
                    }
                  </div>
                </div>
                <div className="pt-3 d-block d-md-none"><Link
                  href="https://www.iasparliament.com/UPSC-IAS-daily-current-videos"
                  target="_blank"
                  className={`${styles.acheiverrank, styles.courseknowmore}`}
                >
                  All Current Affairs
                </Link>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col pt-3 text-center">
                <Link href={"https://youtube.com/playlist?list=PLolj3WrQmJe5DioIDDGDUHlKgGp5KwiVQ"} target={"_blank"} rel="preconnect" className={`${styles.viewbuttonalink}`}>
                  <button type="button" className={`${styles.viewbutton}`}>
                    View All
                  </button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
